export const url = `${
  document.domain === "localhost" ? `http://localhost:8080` : ``
}/api/v1`;

export const goalEvents = {
  Войти: { event_category: "Форма_регистрации", event_name: "Войти" },
  Вход: { event_category: "Кнопка_в_хедере", event_name: "Вход", fbq: "Entrance" },
  Войти_в_кабинет: { event_category: "Форма_входа", event_name: "Войти_в_кабинет" },
  Написать_нам: {
    event_category: "Блок_контактов",
    event_name: "Написать_нам",
    fbq: "WriteToUs",
  },
  Отправить: {
    event_category: "Форма_сообщения",
    event_action: "Отправить",
    event_name: "Contact",
    event_group: "adw",
    fbq: "Contact",
  },
  Попробовать_бесплатно_1: {
    event_category: "Верхняя_кнопка",
    event_name: "Попробовать_бесплатно_1",
    fbq: "TryForFree",
  },
  Попробовать_бесплатно_2: {
    event_category: "Нижняя_кнопка",
    event_action: "Попробовать_бесплатно_2",
    event_name: "InitiateCheckout",
    event_group: "adw",
    fbq: "InitiateCheckout",
  },
  Срок: {
    event_category: "Форма_расчета_стоимости",
    event_action: "Срок",
    event_name: "Term",
    event_group: "adw",
    fbq: "Term",
    fbqCustom: true,
  },
  Пользователей: {
    event_category: "Форма_расчета_стоимости",
    event_action: "Пользователей",
    event_name: "Users",
    event_group: "adw",
    fbq: "Users",
    fbqCustom: true,
  },
  Регистрация: {
    event_category: "Кнопка_в_хедере",
    event_action: "Registration",
    event_name: "Регистрация",
    event_group: "adw",
    fbq: "Registration",
  },
  Зарегистрируйтесь: { 
    event_category: "Форма_входа", 
    event_name: "Зарегистрируйтесь"
  },
  Зарегистрироваться: {
    event_category: "Форма_регистрации",
    event_name: "Зарегистрироваться",
  },
  Возможности: {
    event_category: "Верхнее_меню",
    event_name: "Возможности",
    fbq: "Capabilities",
    fbqCustom: true,
  },
  Стоимость: {
    event_category: "Верхнее_меню",
    event_name: "Стоимость",
    fbq: "Cost",
    fbqCustom: true,
  },
  Контакты: {
    event_category: "Верхнее_меню",
    event_name: "Контакты",
    fbq: "ContactsWatch",
    fbqCustom: true,
  },
  Облако: {
    event_category: "Тип_сервиса",
    event_action: "Облако",
    event_name: "Cloud",
    event_group: "adw",
    fbq: "Cloud",
    fbqCustom: true,
  },
  Коробка: {
    event_category: "Тип_сервиса",
    event_action: "Коробка",
    event_name: "Box",
    event_group: "adw",
    fbq: "Box",
    fbqCustom: true,
  },
  Пользователей_3: {
    event_category: "Форма_расчета_стоимости",
    event_name: "Пользователей_3",
    fbq: "Users3",
    fbqCustom: true,
  },
  Попробовать_бесплатно_3: {
    event_category: "Нижняя_кнопка",
    event_name: "Попробовать_бесплатно_3",
    fbq: "InitiateCheckout1",
    fbqCustom: true,
  },
  Отправить_заявку: {
    event_category: "Отправить_заявку",
    event_name: "Отправить_заявку",
    fbq: "SubmitApplication",
    fbqCustom: true,
  },
};

export const localeConfig = {
  "ru-RU": {
    months: [
      ["none", "бесплатно"],
      ["one", "месяц"],
      ["few", "месяца"],
      ["many", "месяцев"],
    ],
  },
  "uk-UA": {
    months: [
      ["none", "безкоштовно"],
      ["one", "мiсяць"],
      ["few", "мiсяця"],
      ["many", "мiсяцiв"],
    ],
  },
  "en-US": {
    months: [
      ["none", "free"],
      ["one", "month"],
      ["few", "months"],
      ["many", "months"],
      ["other", "months"],
    ],
  },
};

export const scoresLevels = {
  80: "stronger",
  70: "strong",
  60: "good",
  50: "okay",
  25: "weak",
  15: "bad",
};

export const sLevels = {
  "L0": "bad",
  "L1": "weak",
  "L2": "okay",
  "L3": "good",
  "L4": "strong",
  "L5": "stronger",
};

export const curReplace = /(₽|\$|₴|грн\.?)/g;