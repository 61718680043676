import "core-js/stable";
import $ from "jquery";
import Choices from "choices.js";

import { url, localeConfig, curReplace } from "./config";
import { goalIt, fireChange, isNYTerms } from "./helpers";

const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
const ua = navigator.userAgent.toLowerCase();
const isSafari = ~ua.indexOf('safari') && !~ua.indexOf('chrome');

if (document.querySelector('.form-control[name="phone"]')) {
  const phoneField = document.querySelector('.form-control[name="phone"]');
  phoneField.addEventListener("keydown", (e) => {
    if (phoneField.value[0] !== "+") {
      phoneField.value = `+${phoneField.value}`;
    }
    if (
      !e.ctrlKey &&
      (/(Key)/.test(e.code) || (/(Numpad)/.test(e.code) && !/[\d]/.test(e.key)))
    ) {
      e.preventDefault();
      e.stopPropagation();
    }
  });
}

function getPrice() {
  if (!document.querySelector(`.usersCount`)) {
    return null;
  } else {
    const type = document.querySelector('input[name="type"]:checked').value;
    const currency = document.querySelector('input[name="currency"]:checked').value;
    const currencyInfo = window.pricesByCurrency[currency];
    const price = currencyInfo[`${type}_price`];
    const currencySymbol = currencyInfo.currency_symbol;
    const priceCloud = currencyInfo.priceCloud || 0;
    const priceBox = currencyInfo.priceBox || 0;

    let months;
    let discount;
    if (!isIE11) {
      const valueRaw = choice.getValue();
      months = valueRaw.value;
      discount = valueRaw.customProperties.discountValue;
    } else {
      const tempValue = document.querySelector("#monthsCount").value;
      months = document.querySelector(
        `#monthsCount option[value="${tempValue}"]`
      ).value;
      discount = document
        .querySelector(`#monthsCount option[value="${tempValue}"]`)
        .getAttribute("discount");
    }

    if (type === "box") {
      discount = 0;
    }

    let isNewYearDiscount = false;

    if (months === 12 && isNYTerms()) {
      isNewYearDiscount = true;
      if (type === "box") {
        discount = 20;
      }
      // if (type === "cloud") {
      //   discount = 50;
      // }
    }

    const users = usersSelector.value;

    const jsCost = document.querySelector(`.js-cost`);
    const jsDiscount = document.querySelector(`.js-discount`);
    const jsDiscountCost = document.querySelector(`.js-discount-cost`);
    const jsDiscountWrapper = document.querySelector(`.js-discount-wrapper`);
    const jsSymbol = document.querySelectorAll(".js-symbol");
    const jsPriceCloud = document.querySelectorAll('.js-price-cloud');
    const jsPriceBox = document.querySelectorAll('.js-price-box');

    const fullCost = price * months * users;
    const discountValue = (100 - discount) / 100;
    const discountCost = fullCost * discountValue;

    if (jsCost)
      jsCost.textContent = formatNumber(
        fullCost,
        currencyInfo.currency,
        currencyInfo.locale
      ).replace(curReplace, "");

    if (jsDiscount) jsDiscount.textContent = `${discount}%`;

    if (jsDiscountCost)
      jsDiscountCost.textContent = formatNumber(
        discountCost,
        currencyInfo.currency,
        currencyInfo.locale
      ).replace(curReplace, "");

    if (type === "box" && jsDiscountWrapper.classList.contains("show")) {
      jsDiscountWrapper.classList.remove("show");
    } else if (type === "cloud" && !jsDiscountWrapper.classList.contains("show") && discount) {
      jsDiscountWrapper.classList.add("show");
    }

    if (isNewYearDiscount && !jsDiscountWrapper.classList.contains("show") && discount) {
      jsDiscountWrapper.classList.add("show");
    }

    if (jsSymbol.length) {
      [].forEach.call(jsSymbol, (el) => {
        el.textContent = currencySymbol;
      });
    }

    if (jsPriceBox && jsPriceCloud) {
      [].forEach.call(jsPriceBox, (el) => {
        el.textContent = formatNumber(
            priceBox,
            currencyInfo.currency,
            currencyInfo.locale
        ).replace(curReplace, "");
      });
      [].forEach.call(jsPriceCloud, (el) => {
        el.textContent = formatNumber(
            priceCloud,
            currencyInfo.currency,
            currencyInfo.locale
        ).replace(curReplace, "");
      });
    }
  }
}

let choice;
const usersSelector = document.querySelector(`.usersCount`);
const values = [
  { value: 1, discount: 0 },
  { value: 3, discount: 0 },
  { value: 6, discount: 0 },
  { value: 12, discount: 0 },
];

const locale = usersSelector.dataset.locale || "ru-RU";
const suffixes = new Map(localeConfig[locale].months || []);
const localeCurrency = usersSelector.dataset.localeCurrency || "ru-RU";
const currency = usersSelector.dataset.currency || "RUB";

const formatMonths = (n) => {
  if (isIE11 || isSafari) {
    return `${n} ${suffixes.get("other") || suffixes.get("many")}`;
  }
  const pr = new Intl.PluralRules(locale);
  const rule = pr.select(n);
  const suffix = suffixes.get(rule);
  return `${n}\xa0${suffix}`;
};

const formatNumber = (n, fCurrency, fLocaleCurrency) => {
  const lCurrency = fCurrency || currency;
  const lLocaleCurrency = fLocaleCurrency || localeCurrency;

  return new Intl.NumberFormat(lLocaleCurrency, {
    style: "currency",
    currency: lCurrency,
    minimumFractionDigits: lCurrency === "USD" ? 2 : 0,
    maximumFractionDigits: lCurrency === "USD" ? 2 : 0,
  }).format(n);
};

const element = document.getElementById("monthsCount");

if (!isIE11) {
  choice = new Choices(element, {
    searchEnabled: false,
    choice: getPrice,
    choices: values.map((el) => ({
      value: el.value,
      label: formatMonths(el.value),
      selected: el.value === 12,
      customProperties: {
        discountValue: el.discount,
      },
    })),
    callbackOnCreateTemplates: (template) => ({
      item: (classNames, data) =>
        template(`
          <div class="${classNames.item} ${
          data.highlighted
            ? classNames.highlightedState
            : classNames.itemSelectable
        } ${
          data.placeholder ? classNames.placeholder : ""
        }" data-item data-id="${data.id}" data-value="${data.value}" ${
          data.active ? 'aria-selected="true"' : ""
        } ${data.disabled ? 'aria-disabled="true"' : ""}>
            ${data.label}
          </div>
        `),
      choice: (classNames, data) =>
        template(`
          <div class="${classNames.item} ${classNames.itemChoice} ${
          data.disabled ? classNames.itemDisabled : classNames.itemSelectable
        }" data-choice ${
          data.disabled
            ? 'data-choice-disabled aria-disabled="true"'
            : "data-choice-selectable"
        } data-id="${data.id}" data-value="${data.value}" ${
          data.groupId > 0 ? 'role="treeitem"' : 'role="option"'
        }>
            ${data.label}
          </div>
        `),
    }),
    callbackOnInit: () => {
      if (!choice) {
        setTimeout(() => {
          getPrice();
        }, 0);
      }
    },
  });
} else {
  values.map((el) => {
    const option = document.createElement("option");
    option.value = el.value;
    option.setAttribute("discount", el.discount);
    option.textContent = formatMonths(el.value);
    element.add(option);
    return true;
  });
}

$('input[name="type"], input[name="currency"]').on("change", getPrice);
document.querySelector("#monthsCount").addEventListener("change", getPrice);

$(".usersCount").on("change", (e) => {
  getPrice();
  let { min, value } = e.target;
  min = parseInt(min, 10);
  value = parseInt(value, 10);
  const minus = e.target
    .closest(".register-form-info-item-input")
    .querySelector(".js-minus");
  if (value === min) {
    if (!minus.classList.contains("disabled")) {
      minus.classList.add("disabled");
    }
  } else if (minus.classList.contains("disabled")) {
    minus.classList.remove("disabled");
  }
});

$(".usersCount").on("keydown", (e) => {
  let { min, value } = e.target;
  min = parseInt(min, 10);
  value = parseInt(value, 10);
  if (e.which === 13) {
    if (value < min) {
      e.target.value = min;
      fireChange(e.target);
    }
    setTimeout(e.target.blur(), 100);
  }
});

$(".usersCount").on("blur", (e) => {
  let { min, value } = e.target;
  min = parseInt(min, 10);
  value = parseInt(value, 10);
  if (value < min) {
    e.target.value = min;
    fireChange(e.target);
  }
});

$(".js-plus").on("click", (e) => {
  const count = e.target.parentElement.querySelector(".usersCount");
  const value = parseInt(count.value, 10);
  count.value = value + 1;
  fireChange(count);
});

$(".js-minus").on("click", (e) => {
  const count = e.target.parentElement.querySelector(".usersCount");
  const value = parseInt(count.value, 10);
  count.value = value - 1 < count.min ? count.min : value - 1;
  fireChange(count);
});

if (document.querySelector("#webpassForm")) {
  document.querySelector("#webpassForm").addEventListener("submit", (event) => {
    event.preventDefault();

    const form = document.querySelector(`#webpassForm`);

    if (form.checkValidity()) {
      document.querySelector(`#webpassForm .loader`).classList.add("show");

      const { goalId } = event.target.dataset;
      if (goalId) goalIt(goalId);

      const body = {};
      ["name", "phone", "company"].map((el) => {
        if (form.elements[el].value) {
          body[el] = form.elements[el].value;
        }
        return true;
      });

      const email = form.elements.email.value;

      let utmData;
      const utmFieldLabels = {
        source: 'Источник',
        campaign: 'Кампания',
      };

      try {
        utmData = JSON.parse(localStorage.getItem('utmData'));
      } catch (e) {}

      let messageBody = `Регистрация, 1 шаг от ${form.elements.email.value}\n${Object.entries(
          body
      )
          .map((el) => `${el[0]}: ${el[1]}`)
          .join(",\n")}`;

      if (utmData) {
        messageBody += `\n`;

        ['source', 'campaign',].forEach((field) => {
          messageBody += ` \n${utmFieldLabels[field]}: ${utmData[field]}`;
        });
      }

      const data = {
        email,
        body: encodeURIComponent(messageBody),
      };
      $.post({
        url: `${url}/feedback/landing/`,
        data,
        success: () => {
          [].forEach.call(
            document.querySelectorAll("#webpassForm .form-group"),
            (el) => {
              el.classList.remove("was-validated");
            }
          );
          localStorage.setItem("email", email);
          setLocale(document.documentElement.lang);
          window.location.assign("/app/signup/");
        },
        error: () => {
          document
            .querySelector(`#webpassForm .loader`)
            .classList.remove("show");
        },
      });
    } else {
      [].forEach.call(
        document.querySelectorAll("#webpassForm .form-group"),
        (el) => {
          el.classList.add("was-validated");
        }
      );
    }
  });
}
