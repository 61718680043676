import { goalEvents } from "./config";

const fbqTracking = [];
export function goalIt(id) {
  try {
    const counterId = window.Ya._metrika.wv2Counter;
    try {
      window.ym(counterId, "reachGoal", id);
    } catch (err) {
      // console.log(err)
    }
    if (goalEvents[id]) {
      try {
        const gtagEvent = {
          event_category: goalEvents[id].event_category,
          send_to: ['default'],
        };
        if (goalEvents[id].event_action) gtagEvent.event_action = goalEvents[id].event_action;
        if (goalEvents[id].event_group) {
          gtagEvent.send_to = ['default', goalEvents[id].event_group];
        }
        window.gtag("event", goalEvents[id].event_name, gtagEvent);
      } catch (err) {
        // console.log(err);
      }
      if (goalEvents[id].fbq) {
        try {
          if (!fbqTracking.includes(goalEvents[id].fbq)) {
            window.fbq(
              goalEvents[id].fbqCustom ? "trackCustom" : "track",
              goalEvents[id].fbq
            );
            fbqTracking.push(goalEvents[id].fbq);
          }
        } catch (err) {
          // console.log(err);
        }
      }
    }
  } catch (err) {
    // console.log(err);
  }
}

export const fireChange = (target) => {
  const evt = document.createEvent("HTMLEvents");
  evt.initEvent("change", true, true);
  target.dispatchEvent(evt);
};

export const isNYTerms = () => {
  let start = '2020-12-14T00:00';
  // if (
  //   (
  //     document.location.host === 'webpass.develop' ||
  //     document.location.host === 'localhost:8080'
  //   ) &&
  //   document.location.search &&
  //   document.location.search.substr(1).split('&').filter(el => el.split('=')[0] === 'ds')
  // ) {
  //   start = document.location.search.substr(1).split('&').filter(el => el.split('=')[0] === 'ds')[0].split('=')[1];
  // }
  const end = '2021-01-14T23:59';

  return new Date().valueOf() > new Date(start).valueOf() && new Date().valueOf() < new Date(end).valueOf()
}


export const closeModal = (target) => {
  target.classList.remove("open");
  document.body.style.overflow = ''
}

export const openModal = (target) => {
  target.classList.add("open");
  document.body.style.overflow = 'hidden'
}
